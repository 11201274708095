<template>
    <v-card>
        <v-card-text>
            <v-layout wrap>
                <v-flex xs12 lg12 pt-1 pb-2>
                    {{ title }}
                    <strong v-if="point && point.name">{{ point.name }} (</strong>
                    <strong>{{ id }}</strong>
                    <strong v-if="point && point.name">)</strong>
                </v-flex>
                <v-flex xs12 py-0 v-if="point && point.address">
                    <strong>Endereço:</strong>
                    {{ point.address }}
                </v-flex>
                <v-flex xs12 pb-1 pt-0 v-if="point && point.city && point.city.state">
                    <strong>Cidade:</strong>
                    {{ point.city.name }} - {{ point.city.state.initials }}
                </v-flex>
                <v-flex xs12 lg12 pb-1 pt-0 v-else-if="point && point.city">
                    <strong>Cidade:</strong>
                    {{ point.city.name }}
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "PointDetails",
    props: {
        title: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        point: {
            type: Object,
        },
    },
};
</script>
<template>
    <v-container grid-list-xl>
        <v-layout wrap>
            <v-flex xs6>
                <v-img :src="require('@/assets/images/logoIFluks.jpg')" aspect-ratio="1" max-width="250" max-height="130" class="pa-0 ma-0 align-center" alt="iFluks" />
            </v-flex>
            <v-flex xs6>
                <v-img :src="require('@/assets/images/logoRed.png')" aspect-ratio="1" max-width="200" max-height="104" class="pa-0 ma-0 align-center" alt="INCHARGE" />
            </v-flex>

            <v-container v-if="data" class="pa-2 py-0 my-1 mt-0" fluid>
                <Point :point="data.point" :id="id" title="Sessão de carregamento:"></Point>
            </v-container>

            <v-progress-linear v-if="data == null" indeterminate />

            <v-container v-else class="pa-2 py-1 mb-1" fluid v-for="(plug, i) in data.plugs" :key="i">
                <v-card :class="colorPanel(plug)" dark>
                    <v-card-text class="white--text">
                        <v-row>
                            <v-col>
                                <div class="headline">
                                    <v-icon dark v-if="plug.status == 1">power</v-icon>
                                    <v-icon dark v-else-if="plug.status == 0">done_outline</v-icon>
                                    <v-icon dark v-else>cloud_off</v-icon>
                                    Plug {{ plug.number }}
                                </div>
                                {{ textStatus(plug) }}
                            </v-col>

                            <v-col v-if="!$store.getters.isLogged || plug.statusAccess == 'NOT_FOUND'">
                                <p class="text-right font-weight-black">Faça login para obter mais informações.</p>
                            </v-col>
                            <div v-else>
                                <v-col v-if="plug.statusAccess == 'OK' && plug.status == 1 && plug.minutesConnected >= 0" class="text-left">
                                    <div class="headline">{{ plug.minutesConnected }} minuto(s)</div>
                                    Tempo conectado
                                </v-col>
                                <v-col v-if="plug.statusAccess == 'NOT_ACCEPTABLE'" class="text-right">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="reportProblem" class="mb-2" color="secondary" v-on="on"> <v-icon left>sync_problem</v-icon>Reportar problema</v-btn>
                                        </template>
                                        <span>Carregamento já reconhecido por outro usuário, se é sua bike que está conectada, clique acima para reportar problema.</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col v-if="plug.status == 0" class="text-right">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-if="data.price > 0" @click="redirect(plug.number)" class="mb-2" color="error" v-on="on" :disabled="block">
                                                <v-icon left>add_shopping_cart</v-icon>Iniciar
                                            </v-btn>
                                            <v-btn v-else @click="recognize(plug.number)" class="mb-2" color="error" v-on="on" :disabled="block">
                                                <v-icon left>add_shopping_cart</v-icon>{{ data.time }} minutos
                                            </v-btn>
                                        </template>
                                        <span>Clique aqui para iniciarmos.</span>
                                    </v-tooltip>
                                </v-col>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-container>
        </v-layout>
    </v-container>
</template>

<script>
import Point from "@/components/shared/Point";

export default {
    components: {
        Point,
    },

    mounted() {
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
        }
        this.update();
        this.timer = setInterval(this.update, 5000);
    },

    data() {
        return {
            id: null,
            data: null,
            block: false,
            timer: null,
        };
    },

    methods: {
        update() {
            this.$http
                .get("api/v2/ebike/" + this.id, { continuous: true })
                .then((result) => {
                    this.data = result;
                    this.block = false;
                })
                .catch(() => {
                    this.data = null;
                });
        },
        redirect(plug) {
            this.$router.replace({ path: `/ebike/${this.id}/${plug}` });
        },
        recognize(plug) {
            this.block = true;
            this.$http
                .post("api/v2/ebike/recognize", { charger: this.id, plug: plug })
                .then((result) => {
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Enviado com sucesso.");
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar, tente novamente.");
                });
        },
        reportProblem() {
            this.$http
                .post("api/v2/report-problem", { charger: this.id })
                .then((result) => {
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Problema reportado com sucesso.");
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar.");
                });
        },
        textStatus(plug) {
            return plug.status === 1 ? "Carregando" : plug.status === 0 ? "Disponível" : "Offline";
        },
        colorPanel(plug) {
            return plug.status === 1 ? "error" : plug.status === 0 ? "success" : "grey";
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>